import Image from 'next/image';
import Link from 'next/link';
import { FC } from 'react';

type Props = {
    hasLogo: boolean;
    onLogout?: () => void;
};

const Navbar: FC<Props> = ({ hasLogo, onLogout }) => {
    return (
        <nav className="fixed z-10 text-black top-0 bg-white w-full max-w-screen-xs mx-auto py-4 px-6 flex items-center justify-between">
            <Link href="/">
                <Image
                    className={`transition-all duration-200 ${
                        hasLogo ? 'opacity-100' : 'opacity-0'
                    }`}
                    width={100}
                    height={10}
                    alt="volvo"
                    src="/volvo-logo.svg"
                />
            </Link>

            <div className="flex items-center justify-end gap-x-6">
                {onLogout && (
                    <button type="button" onClick={onLogout}>
                        <p className="text-md bg-gray-50 p-2 rounded-md font-base hover:bg-gray-200 transaction duration-150">
                            Logout
                        </p>
                    </button>
                )}
                {/* <button type="button">
          <Image 
            width={23}
            height={14}
            alt="hamburger menu"
            src="/hamburger-menu.svg"
          />
        </button> */}
            </div>
        </nav>
    );
};

export default Navbar;
