'use client'
import { BackCar } from '@/app/components/back-car'
import Navbar from '@/app/components/navbar'
import { useLogout } from '@/app/hooks/cms'
import { FC, Fragment, ReactNode } from 'react'
import { usePathname, useRouter, useSearchParams } from 'next/navigation'

const ProtectedLayout:FC<{ children: ReactNode }> = ({ children }) => {
  const { mutate: logout } = useLogout();

  // get current path
  const path = usePathname();

  return (
    <Fragment>
      <Navbar hasLogo onLogout={path.includes('/otp') ? undefined : logout} />
      <div className="mt-12 w-full z-50">
      {children}
      </div>
      <div className="absolute bottom-0 right-0 z-10">
        <BackCar />
      </div>
    </Fragment>
  )
}



export default ProtectedLayout